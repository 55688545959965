/* TODO(Alan Morehead): Set dimensions as data-attributes, instead of explicity sizing for each format */
function resizeAds() {
  $('.dfp-ad-responsive').each(function adResizeLoop() {
    const scale = Math.min(1, ($(this).width() / $(this).data('width')));
    const originalHeight = $(this).data('height');
    const offset = (Math.round(originalHeight * scale) - originalHeight) / 2;
    $(this).contents().find('iframe').css({ transform: `scale(${scale}`, 'margin-top': offset, 'margin-bottom': offset });
  });
}

$('.dfp-ad-responsive iframe').on('load', () => {
  resizeAds();
});

if (typeof googletag !== 'undefined' && googletag != null && googletag != '') {
  window.addEventListener('resize', resizeAds);
  googletag.cmd.push(() => { googletag.pubads().addEventListener('slotRenderEnded', resizeAds); });
}
